<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen">
    <vx-card>
      <div slot="no-body" class="full-page-bg-color">
        <!-- <iframe
          width="100%"
          height="600"
          :src="meeting_link"
          allow="camera *;microphone *"
          v-if="meeting_link"
          allowfullscreen
        ></iframe> -->
        <div class="p-10 text-center">
          <!-- <h3 class="text-center text-color-base">{{ siteTitle }} </h3> -->
          <template v-if="meetingInfo">
            <h3 class="py-2">{{ meetingInfo.subjectName }}</h3>
            <h5 class="py-2">{{ meetingInfo.teacher_name }}</h5>

            <div class="vx-row py-2">
              <div class="vx-col w-full md:w-1/3">
                <p class="mb-1 text-sm inline-block mr-5">
                  <feather-icon
                    icon="CalendarIcon"
                    svgClasses="h-4 w-4 mr-2 "
                  />
                  {{ meetingInfo.date }}
                </p>
              </div>
              <!-- Classs start time && period -->
              <div class="vx-col w-full md:w-1/3">
                <p class="text-sm inline-block">
                  <template v-if="meetingInfo.time_from">
                    <feather-icon icon="ClockIcon" svgClasses="h-4 w-4 mr-2 " />
                    {{ meetingInfo.time_from }}
                  </template>
                </p>
              </div>
              <div class="vx-col w-full md:w-1/3">
                <p v-if="meetingInfo.time_from" class="block py-1">
                  <feather-icon icon="ClockIcon" svgClasses="h-4 w-4 mr-2 " />
                  {{ meetingInfo.classPriod }}
                </p>
              </div>
            </div>
          </template>
          <div class="vx-col w-full md:w-1/3 mx-auto mt-5">
            <p class="block py-1 text-danger" v-if="err">
              {{ err }}
            </p>
            <vs-button
              class="block mx-auto my-5"
              v-else-if="meeting_link"
              @click="goToMeeting()"
            >
              {{ $t("onlineClass.join_the_meeting") }}</vs-button
            >
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { RepositoryFactory } from '@/Repositories/RepositoryFactory'
const TeacherRepository =  RepositoryFactory.get('TeachersModule', 'OnlineClass')
export default {
  data () {
    return {
      meeting_link: '',
      meetingInfo: '',
      err:''
    }
  },
  created () {
    this.show()
    this.joinMeeting()
  },
  methods: {
    joinMeeting () {
      TeacherRepository.getJoinMeetingLink(this.$route.params.meeting_id)
        .then((res) => {
          this.meeting_link = res
          this.goToMeeting()
        })
        .catch((err) => {
          this.err = err.data.error.message
          this.errMsg(err)
        })
    },
    show () {
      this.VSLoading()
      TeacherRepository.show(this.$route.params.meeting_id)
        .then((res) => {
          this.meetingInfo = res.data
        })
        .catch(this.errMsg)
        .finally(this.stopVSLoading)
    },
    goToMeeting (link = this.meeting_link) {
      window.open(link, '_blank')
    }
  },
  computed: {}
}
</script>
<style lang="scss">
.transition {
  transition: all 0.5s ease;
}

.register-tabs-container {
  max-height: 700px;
  overflow-y: auto;

  .con-tab {
    padding-bottom: 23px;
  }

  select,
  label,
  button span,
  .vs-select--options span {
    text-transform: capitalize;
  }
}

.vue-form-wizard.md .wizard-navigation .wizard-nav .wizard-icon-circle {
  width: 45px;
  height: 45px;

  .wizard-icon {
    font-size: 1.2rem;
  }
}

span.text-danger {
  text-transform: capitalize;
  font-size: 0.875rem !important;

  &.w-full {
    display: block;
  }
}

.fullscreen {
  position: absolute;
  right: 15px;
  top: 10px;

  .vs-button-primary.vs-button-filled {
    background-color: #eff2f7 !important;
    color: #2c2c32;
  }
}
</style>
